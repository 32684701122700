import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Reservations from './pages/reservations';
import Checkout from './pages/checkout';
import { UserProvider } from './contexts/UserContext'; 

const App = () => {
  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route 
            path="/" 
            element={<Reservations />} 
          />
          <Route 
            path="/reservation" 
            element={<Reservations />} 
          />
          <Route 
            path="/checkout" 
            element={<Checkout />} 
          />
        </Routes>
      </Router>
    </UserProvider>
  );
};

export default App;
